<template>
     <div class="contract_quo">
          <div class="info_main">
               <div class="member_title_breadcrumb">
                    <el-breadcrumb separator-class="el-icon-arrow-right" style="line-height: 40px;font-size: 10px;">
                         <el-breadcrumb-item :to="`/inquiry/purchaser/inq_info?inquireId=${info.inquireId}`">{{
                              L['询盘详情'] }}
                         </el-breadcrumb-item>
                         <el-breadcrumb-item>{{ L['报价详情'] }}</el-breadcrumb-item>
                    </el-breadcrumb>
               </div>

               <div class="info_z">
                    <div class="main1 flex-col">
                         <div class="main2 flex-col">
                              <div class="outer4">
                                   <span class="txt1">{{ L['状态：'] }}</span>
                                   <span class="txt2">{{ info.stateValue }}</span>
                              </div>
                              <div class="outer4">
                                   <span class="txt1">{{ L['报价时间：'] }}</span>
                                   <span class="txt4">{{ info.createTime }}</span>
                              </div>

                              <div class="outer4">
                                   <span class="txt1">{{ L['报价供应方：'] }}</span>
                                   <span class="txt4">{{ info.quoteEnterpriseName }}</span>
                              </div>
                              <div class="outer4">
                                   <span class="txt1">{{ L['联系人：'] }}</span>
                                   <span class="txt4">{{ info.contactName }}</span>
                              </div>

                         </div>
                    </div>
                    <div class="outer7">
                         <div class="outer5 flex-col">
                              <span class="word7">{{ L['报价信息'] }}</span>
                         </div>

                         <div class="word4">{{ L['产品报价'] }}</div>

                         <div class="section8">
                              <el-table :data="info.productList" style="width: 100%">
                                   <el-table-column type="index" width="100" :label="L['序号']" align="center" />
                                   <el-table-column width="120" :label="L['产品图片']" align="center">
                                        <template #default="scope">
                                             <div class="flex_row_center_center">
                                                  <img class="pic3" :src="scope.row.productImage"
                                                       v-if="scope.row.productImage" />

                                                  <img class="pic3" src="@/assets/inquiry/inq_icon4.png" v-else />
                                             </div>
                                        </template>
                                   </el-table-column>
                                   <el-table-column prop="productName" width="180" :label="L['产品名称']" align="center" />

                                   <el-table-column prop="spec" width="140" :label="L['规格型号']" align="center" />
                                   <el-table-column width="120" :label="L['数量']" align="center">
                                        <template #default="scope">
                                             <div class="flex_row_center_center">
                                                  <span>{{ scope.row.buyNum }}{{ scope.row.unitName }}</span>
                                             </div>
                                        </template>
                                   </el-table-column>
                                   <el-table-column prop="taxPrice" width="110" :label="L['单价(元)']" align="center" />
                                   <el-table-column prop="price" width="150" :label="L['未税单价(元)']" align="center" />
                                   <el-table-column prop="taxRate" width="120" :label="L['税率']" align="center" />
                                   <el-table-column prop="totalAmount" width="117" :label="L['小计']" align="center" />
                              </el-table>
                         </div>

                         <div class="word4">{{ L['其他费用'] }}</div>

                         <div class="section8">
                              <el-table :data="info.feeList" style="width: 100%">
                                   <el-table-column prop="feeName" width="231" :label="L['收费项']" align="center" />
                                   <el-table-column prop="feeTax" width="231" :label="L['报价(元)']" align="center" />
                                   <el-table-column prop="fee" width="231" :label="L['未税报价(元)']" align="center" />
                                   <el-table-column prop="taxRate" width="231" :label="L['税率']" align="center" />
                                   <el-table-column prop="feeTax" width="231" :label="L['小计']" align="center" />
                              </el-table>
                         </div>

                         <div class="total1 flex_column_center_end">
                              <div class="outer17 flex_row_start_center">
                                   <div class="group3">
                                        <span class="word36">{{ L['未税价：'] }}</span>
                                        <span class="info15">{{ L['¥'] }}</span>
                                        <span class="info15">{{ getPartNumber(info.totalAmount, 'int') }}</span>
                                        <span class="word37">{{ getPartNumber(info.totalAmount, 'decimal') }}</span>
                                   </div>
                              </div>
                              <div class="outer17 flex_row_start_center">
                                   <div class="group3">
                                        <span class="word36">{{ L['含税：'] }}</span>
                                        <span class="info15">{{ L['¥'] }}</span>
                                        <span class="info15">{{ getPartNumber(info.taxAmount, 'int') }}</span>
                                        <span class="word37">{{ getPartNumber(info.taxAmount, 'decimal') }}</span>
                                   </div>
                              </div>
                              <div class="outer17 flex_row_start_center">
                                   <div class="group3">
                                        <span class="word36">{{ L['合计：'] }}</span>
                                        <span class="info15">{{ L['¥'] }}</span>
                                        <span class="info15">{{ getPartNumber(info.totalAmountTax, 'int') }}</span>
                                        <span class="word37">{{ getPartNumber(info.totalAmountTax, 'decimal') }}</span>
                                   </div>
                              </div>
                         </div>
                    </div>


                    <div class="outer8">
                         <div class="outer5 flex-col">
                              <span class="word7">{{ L['交易信息'] }}</span>
                         </div>
                         <div class="section9">

                              <div class="part1 flex_row_start_center">
                                   <span class="info19">{{ L['结算方式：'] }}</span>
                                   <span class="paragraph3">{{ info.billTypeName }}<br /></span>
                              </div>

                              <div class="part1 flex_row_start_center">
                                   <span class="info19">{{ L['发票类型：'] }}</span>
                                   <span class="paragraph3">{{ invoiceDTO[info.invoiceType] }}<br /></span>
                              </div>
                         </div>
                    </div>

                    <div class="outer9">
                         <div class="outer5 flex-col">
                              <span class="word7">{{ L['联系人信息'] }}</span>
                         </div>
                         <div class="flex_row_start_center section10">
                              <span class="txt28">{{ L['联系人姓名：'] }}</span>
                              <span class="infoBox3">{{ info.contactName }}</span>
                         </div>
                         <div class="flex_row_start_center section10">
                              <span class="txt28">{{ L['手机号码：'] }}</span>
                              <span class="infoBox3">{{ info.contactMobile }}</span>
                         </div>
                    </div>

                    <div class="outer9">
                         <div class="outer5 flex-col">
                              <span class="word7">{{ L['其他说明'] }}</span>
                         </div>
                         <div class="flex_row_start_center section10">
                              <span class="txt28">{{ L['配送方式：'] }}</span>
                              <span class="infoBox3">{{ info.deliverTypeName }}</span>
                         </div>
                         <div class="flex_row_start_center section10">
                              <span class="txt28">{{ L['预计到货：'] }}</span>
                              <span class="infoBox3">{{ L['达成合约后'] }}{{ info.deliverDay }}{{ L['天'] }}</span>
                         </div>
                         <div class="flex_row_start_center section10">
                              <span class="txt28">{{ L['其他说明：'] }}</span>
                              <span class="infoBox3">{{ info.description }}</span>
                         </div>
                    </div>

                    <div class="outer29 flex-col" @click="acceptQuo" v-if="info.state == 10 && inquireState == 30">
                         <span class="word28">{{ L['接受报价'] }}</span>
                    </div>
               </div>
          </div>
     </div>
</template>

<script>
import { ref, reactive, getCurrentInstance, onMounted, toRefs, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { inqUtils } from '@/utils/inquiry_utils.js'
import { ElMessage } from 'element-plus'
export default {
     setup() {
          const quoDTO = reactive({
               info: {}
          })
          const { proxy } = getCurrentInstance()
          const route = useRoute()
          const L = proxy.$getCurLanguage()
          const router = useRouter()
          const inquireState = ref(route.query.inquireState)

          const { getPartNumber } = inqUtils()
          const getQuoInfo = () => {
               proxy.$get('v3/business/front/purchaseInquire/quoteDetail', {
                    quoteId: route.query.quoteId
               }).then(res => {
                    if (res.state == 200) {
                         quoDTO.info = res.data
                         console.log(quoDTO.info, 'quoDTO.info')
                    }
               })
          }

          const invoiceDTO = reactive({
               1: L['无需发票'],
               2: L['增值税专用发票'],
               3: L['增值税普通发票']
          })

          const feeTaxAmount = computed(() => {

          })

          onMounted(() => {
               if (route.query.quoteId) {
                    getQuoInfo()
               }
          })

          const acceptQuo = () => {
               let { quoteId } = quoDTO.info
               proxy.$confirm(L['确认接受该报价？'], {
                    confirmButtonText: L['确定'],
                    cancelButtonText: L['取消'],
                    type: 'warning',
                    center: true
               }).then(res => {
                    proxy.$post('v3/business/front/purchaseInquire/confirmQuote', { quoteId }).then(res => {
                         if (res.state == 200) {
                              ElMessage.success(res.msg)
                              getQuoInfo()
                         }
                    })
               })
          }


          return {
               ...toRefs(quoDTO),
               getPartNumber,
               invoiceDTO,
               quoDTO,
               acceptQuo,
               inquireState,
               L
          }
     }
}
</script>



<style lang="scss">
.contract_quo {
     background: #F7F7F7;
     padding-bottom: 50px;

     .info_main {
          width: 1200px;
          margin: 0 auto;

          .member_title_breadcrumb {
               padding-left: 10px;
               background: $colorK;

               .el-breadcrumb__item {
                    height: 46px;
                    line-height: 46px;
               }

               .el-breadcrumb__inner {
                    color: #666666 !important;
                    font-weight: 700 !important;
               }

               .is-link {
                    color: #666666 !important;
                    font-weight: 400 !important;

                    &:hover {
                         color: #333333 !important;
                         font-weight: 700 !important;
                    }
               }
          }

          .info_z {
               background-color: #fff;
               padding: 20px;
               padding-bottom: 37px;


               .main1 {
                    background-color: rgba(180, 189, 208, 0.1);
                    margin-bottom: 18px;

                    .main2 {
                         margin-left: 18px;
                         margin-bottom: 18px;

                         .outer4 {
                              margin-top: 18px;

                              .txt1 {

                                   color: rgba(102, 102, 102, 1);
                                   font-size: 14px;
                              }

                              .txt2 {
                                   color: rgba(40, 95, 222, 1);
                                   font-size: 14px;
                              }


                              .txt4 {
                                   color: rgba(51, 51, 51, 1);
                                   font-size: 14px;
                              }

                              .word3 {
                                   color: rgba(243, 6, 6, 1);
                                   font-size: 14px;
                              }
                         }

                    }
               }

               .outer5 {
                    background-color: rgba(40, 95, 222, 0.1);
                    border-radius: 2px;
                    height: 40px;

                    .word7 {
                         color: rgba(18, 18, 18, 1);
                         font-size: 16px;
                         font-family: SourceHanSansCN-Medium;
                         margin: 12px 0 0 19px;
                    }
               }

               .outer7 {
                    .section8 {
                         margin-top: 14px;
                         border: 1px solid #DCDCDC;
                    }

                    .pic3 {
                         width: 50px;
                         height: 50px;
                    }

                    .word4 {
                         color: #121212;
                         font-size: 14px;
                         margin: 17px;
                    }

                    .total1 {
                         margin-top: 26px;

                         .outer17 {
                              margin-bottom: 18px;

                              .group3 {

                                   .word36 {
                                        color: rgba(51, 51, 51, 1);
                                        font-size: 14px;
                                   }

                                   .info15 {
                                        color: rgba(243, 6, 6, 1);
                                        font-size: 16px;
                                   }

                                   .word37 {
                                        color: rgba(243, 6, 6, 1);
                                        font-size: 14px;
                                   }
                              }

                              .txt21 {
                                   color: rgba(51, 51, 51, 1);
                                   font-size: 14px;
                                   margin-left: 20px;
                              }
                         }
                    }
               }

               .outer8 {
                    margin-top: 20px;

                    .section9 {
                         padding: 20px;

                         .part1 {
                              margin-bottom: 20px;

                              &:last-child {
                                   margin-bottom: 0;
                              }

                              .info19 {
                                   color: rgba(102, 102, 102, 1);
                                   font-size: 14px;
                              }

                              .paragraph3 {
                                   color: rgba(51, 51, 51, 1);
                                   font-size: 14px;
                              }
                         }
                    }


               }

               .outer9 {
                    margin-bottom: 12px;

                    .section10 {
                         margin-top: 10px;
                         padding: 0 20px;

                         .txt28 {
                              color: rgba(102, 102, 102, 1);
                              font-size: 14px;
                              white-space: nowrap;
                         }

                         .infoBox3 {
                              color: rgba(51, 51, 51, 1);
                              font-size: 14px;
                              text-align: left;
                              line-height: 26px;
                              word-break: break-all;
                         }
                    }
               }

          }
     }

     .outer29 {
          background-color: rgba(40, 95, 222, 1);
          border-radius: 6px;
          height: 42px;
          width: 200px;
          margin: 57px 0 0 481px;
          cursor: pointer;

          .word28 {
               width: 65px;
               height: 16px;
               color: rgba(255, 255, 255, 1);
               font-size: 16px;
               font-family: SourceHanSansCN-Regular;
               text-align: left;
               white-space: nowrap;
               line-height: 16px;
               display: block;
               margin: 13px 0 0 68px;
          }
     }
}
</style>